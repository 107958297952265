/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header2 from "./header2"
import "./layout.scss"

const Layout2 = ({ children }) => {


    return (
        <>
            <Header2 />
            <div
                style={{
                    margin: `0 auto`,
                    // maxWidth: 960,
                    // padding: `0 1.0875rem 1.45rem`,
                }}
            >
                <main>{children}</main>
                {/* <footer>
          © {new Date().getFullYear()}, Mail Sculpt
          {` `}
        </footer> */}
            </div>
        </>
    )
}


export default Layout2
