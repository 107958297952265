import React from "react"

import Layout2 from "../components/layout2"

import SEO from "../components/seo"
import { Helmet } from "react-helmet"


import HeroSection from "../components/home-page/heroSection"
import PricingSection from "../components/home-page/pricingSection"
import ShowcaseSection from "../components/home-page/showcaseSection"
import ContactSection from "../components/home-page/contactSection"
import ArchiveSection from "../components/home-page/archiveSection"
import Footer from "../components/footer"

import '../scss/style.scss'

import Checkout from "../components/checkoutHTML"
import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"



const IndexPage = () => (
    <Layout2>
        {/* <SEO title="Success" /> */}

        <Helmet>
            <meta name="robots" content="noindex" />
            <title>Success</title>
        </Helmet>

        <Container style={{ fontFamily: "Roboto" }}>
            <Row style={{ marginTop: "90px" }}>
                <Col>
                    <h1>Thank you for your order request</h1>
                    <p>Your dedicated Email developer will be in contact with you shortly</p>


                </Col>
            </Row>
        </Container>



        <Footer />





    </Layout2 >
)

export default IndexPage
