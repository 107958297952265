import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./images/logo"

import Button from 'react-bootstrap/Button'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import '../scss/style.scss'

const Header2 = () => (
    <header
        style={{
            background: `#192128`,
            marginBottom: `1.45rem`,
            height: `81px`

        }}
    >

        <Navbar fixed="top" className="navbar-dark" expand="md" style={{ backgroundColor: "#192128" }}>

            <Navbar.Brand className="mr-auto headerLogo" style={{ maxWidth: "150px", paddingTop: "16px" }}>       <Link to="/" style={{ textDecoration: "none" }}> <Logo /> </Link> </Navbar.Brand>
            {/* <Nav className="mr-auto ml-auto d-md-none d-lg-none d-xl-none" >
                <Button variant="primary" size="sm" style={{ fontFamily: "Roboto", fontWeight: "bold" }}>Order Now</Button>
            </Nav>
            <Navbar.Toggle className="ml-auto" aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav" >
                <Nav className="ml-auto mr-auto">
                    <Nav.Link className="m-2 active" href="#pricing" style={{ color: "#ffffff", fontFamily: "Roboto, sans-sarif, helvetica" }}>Pricing</Nav.Link>
                    <Nav.Link className="m-2" href="#contact" style={{ color: "#ffffff", fontFamily: "Roboto, sans-sarif, helvetica" }}>Contact Us</Nav.Link>

                </Nav>


            </Navbar.Collapse>

            <Nav className=" d-none d-sm-none d-md-block d-lg-block d-xl-block" >
                <Button variant="primary" style={{ fontFamily: "Roboto", fontWeight: "bold" }}>Order Now</Button>
            </Nav> */}



        </Navbar>

    </header>
)


export default Header2
